// assets
import { IconDashboard } from '@tabler/icons';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import HelpIcon from '@mui/icons-material/Help';

import {useTranslation} from "react-i18next";
import LoyaltyIcon from '@mui/icons-material/Loyalty';
// constant
// const { t, i18n } = useTranslation();
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import HvacIcon from '@mui/icons-material/Hvac';
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const parse = JSON.parse(localStorage.getItem('auth_user'));

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    title_en: 'Dashboard',
    title_ar: 'لوحة التحكم',
    type: 'group',
    children: parse?.company_type && parse?.company_type === process.env.REACT_APP_USER_INDIVIDUAL ?[
        {
            id: 'dashboard',
            title: 'Dashboard',
            title_en: 'Dashboard',
            title_ar: 'لوحة التحكم',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'update_skills',
            title: 'Selected Skills',
            title_en: 'Selected Skills',
            title_ar: 'المهارات المختارة',
            type: 'item',
            url: '/selected-skills',
            icon: Inventory2OutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'my_account',
            title: 'My Account',
            title_en: 'My Account',
            title_ar: 'حسابي',
            type: 'item',
            url: '/my-account',
            icon: PersonOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'contact-us',
            title: 'Contact Us',
            title_en: 'Contact Us',
            title_ar: 'اتصل بنا',
            type: 'item',
            url: '/contact-us',
            icon: HelpIcon,
            breadcrumbs: false
        }
    ]:[
        {
            id: 'dashboard',
            title: 'Dashboard',
            title_en: 'Dashboard',
            title_ar: 'لوحة التحكم',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'cancel_request',
            title: 'Cancel Subscriptions',
            title_en: 'Cancel Subscriptions',
            title_ar: 'إلغاء الاشتراكات',
            type: 'item',
            url: '/cancel-request',
            icon: LoyaltyIcon,
            breadcrumbs: false
        },
        {
            id: 'branches',
            title: 'Branches',
            title_en: 'Branches',
            title_ar: 'الفروع',
            type: 'item',
            url: '/branches',
            icon: Inventory2OutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: 'Users',
            title_en: 'Users',
            title_ar: 'المستخدمون',
            type: 'item',
            url: '/users',
            icon: PeopleAltOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'my_account',
            title: 'My Account',
            title_en: 'My Account',
            title_ar: 'حسابي',
            type: 'item',
            url: '/my-account',
            icon: PersonOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'update_company',
            title: 'Company Information',
            title_en: 'Company Information',
            title_ar: 'معلومات الشركة',
            type: 'item',
            url: '/edit-company',
            icon: Inventory2OutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'contact-us',
            title: 'Contact Us',
            title_en: 'Contact Us',
            title_ar: 'اتصل بنا',
            type: 'item',
            url: '/contact-us',
            icon: HelpIcon,
            breadcrumbs: false
        },
        {
            id: 'order-policy',
            title: 'Order Policies',
            title_en: 'Order Policies',
            title_ar: 'سياسات الطلب',
            type: 'item',
            url: '/policies',
            icon: PrivacyTipIcon,
            breadcrumbs: false
        },
    ]
};

export default dashboard;
