import React, { useMemo, useState, useEffect, useContext } from 'react';
import {Button, Card, CardContent, Grid, TextField, Typography, Link, Box, Pagination} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import ToastContext from 'context/ToastContext';
import {getalladminAppSetting, getAllProjects, readNotification} from 'api/api';
import {useTranslation} from "react-i18next";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import {useDispatch, useSelector} from "react-redux";
import {getProjectList} from "../../store/actions";

const CustomerProject = () => {
    const { t, i18n } = useTranslation();
    const toastOptions = useContext(ToastContext);
    const [searchText, setSearchText] = useState('');
    const [specialityId, setSpecialityId] = useState('');
    const [filterStatusArr, setFilterStatusArr] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [appSetting, setAppSetting] = useState();
    const [customerOpen, setCustomerOpen] = useState(false);
    const [customer, setCustomer] = useState();
    const rows = useSelector(state => state.customerProject?.data);
    const IsActive = useSelector(state => state.customerProject?.loading);
    const totalCount = useSelector(state => state.customerProject?.totalCount);
    const specialityArr = useSelector(state => state.customerProject?.specialityArr);
    const dispatch = useDispatch();

    useEffect(() => {
        const formData = {
            page_number: 1,
        }
        callProjectAPI(formData, true)
    }, [dispatch]);

    const columns = useMemo(() => [
        { field: 'name', headerName: t('Name'), flex: 0.8,
            renderCell: ({ row }) => {
                return (i18n.language==='ar'?row.title_ar:row.title_en);
            }
        },
        {
            field: 'city',
            headerName: t('City'),
            flex: 0.5,
            renderCell: ({ row }) => {
                return (i18n.language==='ar'?row.city?.name_ar:row.city?.name_en);
            }
        },
        {
            field: 'date',
            headerName: t('Publish Date'),
            flex: 0.5,
            renderCell: ({ row }) => {
                return (
                    moment(row?.createdAt).format('YYYY-MM-DD')
                );
            }
        },
        {
            field: 'delivery date',
            headerName: t('Delivery Date'),
            flex: 0.5,
            renderCell: ({ row }) => {
                return (
                    row?.delivery_date
                );
            }
        },
        {
            field: 'speciality',
            headerName: t('Specialities'),
            flex: 1.4,
            renderCell: ({ row }) => {

                return (
                    <div style={{display: 'flex', marginTop:'0.5rem', flexWrap: 'wrap'}}>
                        {row.speciality_arr.map((e, index) => (
                            <span key={index} style={{
                                backgroundColor: index % 2 === 0 ? '#00C853' : '#ffd13d',
                                color: index % 2 === 0 ? '#fff': '#000',
                                padding: '5px 10px',
                                borderRadius: '15px',
                                marginRight: '0.25rem', // Add space between items vertically within the row
                                marginLeft: '0.25rem', // Add space between items vertically within the row
                                marginBottom: '0.5rem',
                            }}>
            {i18n.language === 'ar' ? e.title_ar : e.title_en}
          </span>
                        ))}
                    </div>
                )

            }
        },
        {
            field: 'action',
            headerName: '',
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Button href={row.project_url}
                            target="_blank"
                            variant="contained" color="secondary" size="small">
                        {t('View')}
                    </Button>
                );
            }
        },
    ]);

    const callProjectAPI = (formData, isLoading) => {
        formData.is_provider = true;
        formData.item_limit = 30;
        dispatch(getProjectList(formData, isLoading, i18n));
    }
    const handleSearchKeyUp = (event) => {
        const search_text = event.target.value;
        setSearchText(search_text);
        setPageNumber(1);
        if(!search_text || search_text.length > 3){
            const formData  ={
                search_text: search_text,
                speciality_id_arr: specialityId,
                page_number: 1,
            }
            callProjectAPI(formData);
        }
    }

    const onPageChange = (event, value) => {
        setPageNumber(value);
        window.scrollTo({ top: 0, behavior: "smooth" })
        const formData  ={
            search_text: searchText,
            speciality_id_arr: specialityId,
            page_number: value,
        }
        callProjectAPI(formData);
    }

    const handleFilterClick = (event) => {
        const speciality_id = event.target.value;
        setSpecialityId(speciality_id);
        setPageNumber(1);
        window.scrollTo({ top: 0, behavior: "smooth" })
        const formData  ={
            search_text: searchText,
            speciality_id_arr: speciality_id,
            page_number: 1,
        }
        callProjectAPI(formData);
    }

    const handleCustomerClose = () => {
        setCustomerOpen(false);
    };

    return (
        <>
            {IsActive ? (
                <SkeletonEarningCard />
            ) : (
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h3">{t('Projects')}</Typography>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                <Typography sx={{fontWeight: 'bold',}}>{t('Search')}: </Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    placeholder={t('Search')}
                                    fullWidth
                                    value={searchText}
                                    onChange={handleSearchKeyUp}
                                    name="search"
                                />
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                <Typography sx={{fontWeight: 'bold',}}>{t('Filter')}: </Typography>
                                <Select
                                    fullWidth
                                    onChange={handleFilterClick}
                                    value={specialityId}>
                                    <MenuItem key={0} value=''>{t('All')}</MenuItem>
                                    {
                                        specialityArr && specialityArr.length > 0 &&
                                        specialityArr.map((e) => (
                                            <MenuItem key={e._id} value={e._id}>{i18n.language==='ar'?e.title_ar:e.title_en}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {' '}
                                <DataGrid
                                    getRowHeight={() => 'auto'}
                                                      getEstimatedRowHeight={() => 200}
                                    disableRowSelectionOnClick
                                    rows={rows}
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    hideFooterPagination={true}
                                    sx={{
                                        border: 0,
                                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                            outline: 'none !important'
                                        }
                                    }}
                                />
                            </Grid>

                            {
                                totalCount >30 ?(
                                    <Grid item lg={12} md={12} xs={12} sm={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}>
                                            <Pagination count={ Math.ceil(totalCount/30)} page={pageNumber} onChange={onPageChange} />
                                        </Box>
                                    </Grid>
                                ):(
                                    <></>
                                )
                            }
                        </Grid>
                        {
                            customer && (
                                <Dialog open={customerOpen} fullWidth maxWidth="sm" onClose={handleCustomerClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                    <DialogContent>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Customer Info</Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                                                            <Typography sx={{mb: 1}}>{t('Image')}: </Typography>
                                                            <img
                                                                src={customer?.media ? customer?.media : process.env.REACT_APP_USER_IMG}
                                                                alt="image" width="50"/>

                                                        </Box>
                                                        <Typography sx={{ mb: 1, }}>{t('Name')}: {customer.name}</Typography>
                                                        <Typography sx={{ mb: 1,  }}>{t('Phone')}: &nbsp;
                                                            <Link href={`tel:${customer.phone}`}>
                                                                 {customer.phone}
                                                            </Link>
                                                        </Typography>
                                                        <Typography sx={{ mb: 1,  }}>{t('Email')}: &nbsp;
                                                            <Link href={`mailto:${customer.email}`}>
                                                                 {customer.email}
                                                            </Link>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCustomerClose} variant="contained">
                                            {t('Close')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )
                        }
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default CustomerProject;
