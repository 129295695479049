import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Link,
    Box,
    Pagination,
    FormControlLabel, Radio, RadioGroup, FormHelperText
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import ToastContext from 'context/ToastContext';
import {
    getalladminAppSetting,
    getAllProjects,
    getOrderPolicies,
    readNotification,
    updateOrderPolicies,
    updateProvider
} from 'api/api';
import {useTranslation} from "react-i18next";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import {useDispatch, useSelector} from "react-redux";
import {getProjectList} from "../../store/actions";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {Formik} from "formik";
import DropZone from "../../ui-component/DropZone";
import LoadingButton from "@mui/lab/LoadingButton";
import AWS from "aws-sdk";
import pdf_thumb from 'assets/images/pdf.png'

const Policy = () => {
    const current = JSON.parse(localStorage.getItem('auth_user'));
    const { t, i18n } = useTranslation();
    const toastOptions = useContext(ToastContext);
    const [isLoading, setIsLoading] = useState(true);
    const [ButtonLoading, setButtonLoading] = useState(false);
    const [ButtonLoading2, setButtonLoading2] = useState(false);
    const [file, setFile] = useState();
    const [privacyPolicy, setPrivacyPolicy] = useState();
    const [termsConditions, setTermsConditions] = useState();

    useEffect(() => {
        const formData = {
            company_id: current?.company_id,
            user_id: current?._id,
            tag: 'ALL',
            platform: process.env.REACT_APP_PLATFORM,
        }
        getOrderPolicies(formData).then((res) => {
            const response = res.data;
            setIsLoading(false);
            if (response.code === 1) {
                setTermsConditions(response.data.find((e) => e.tag === process.env.REACT_APP_TERMS_CONDITIONS));
                setPrivacyPolicy(response.data.find((e) => e.tag === process.env.REACT_APP_PRIVACY_POLICY));
            }
        })
    }, []);

    const handlePolicySubmit = async (values, resetForm) => {
        let isUploaded = false;
        if(values.tag === process.env.REACT_APP_PRIVACY_POLICY){
            setButtonLoading(true);
        }
        else if(values.tag === process.env.REACT_APP_TERMS_CONDITIONS){
            setButtonLoading2(true);
        }
        if (values.type === 'URL') {
            try {
                const file = values.policy_value;
                const settings = JSON.parse(localStorage.getItem('settings'));
                AWS.config.update({
                    accessKeyId: settings.aws_access_key,
                    secretAccessKey: settings.aws_secret_key,
                    region: settings.aws_region
                });
                const s3 = new AWS.S3();
                let extension = file.name.split('.').pop();
                const params = {
                    ACL: 'public-read',
                    Bucket: settings.aws_bucket_name,
                    Key: `omran/company/policy/${Date.parse(new Date())}.${extension}`,
                    Body: file
                };

                const response = await s3.upload(params).promise();
                values.policy_value = response.Location;
                isUploaded = true;

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        if(!isUploaded && values.type === 'URL'){
            if(values.tag === process.env.REACT_APP_PRIVACY_POLICY){
                setButtonLoading(false);
            }
            else if(values.tag === process.env.REACT_APP_TERMS_CONDITIONS){
                setButtonLoading2(false);
            }
            toast.error(t('Error while uploading file try again'));
        }
        else{
            updateOrderPolicies(values).then((res) => {
                const response = res.data;
                if(values.tag === process.env.REACT_APP_PRIVACY_POLICY){
                    setButtonLoading(false);
                }
                else if(values.tag === process.env.REACT_APP_TERMS_CONDITIONS){
                    setButtonLoading2(false);
                }
                if(response.code===1) {
                    // const { type,platform,tag,company_id,user_id, ...rest } = values;
                    // resetForm({
                    //     values: {
                    //         type,platform,tag,company_id,user_id,
                    //         ...Object.keys(rest).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
                    //     },
                    // });
                    if(values.tag === process.env.REACT_APP_PRIVACY_POLICY){
                        setPrivacyPolicy(response.data);
                    }
                    else if(values.tag === process.env.REACT_APP_TERMS_CONDITIONS){
                        setTermsConditions(response.data);
                    }
                    toast.success(i18n.language==='ar'?response.ar_message:response.en_message)
                }
                else{
                    toast.error(i18n.language==='ar'?response.ar_message:response.en_message)
                }
            }).catch(err => {
                if(values.tag === process.env.REACT_APP_PRIVACY_POLICY){
                    setButtonLoading(false);
                }
                else if(values.tag === process.env.REACT_APP_TERMS_CONDITIONS){
                    setButtonLoading2(false);
                }
                toast.error(i18n.language==='ar'?err.response?.data?.ar_message:err.response?.data?.en_message);
            });
        }

    }


    return (
        <>
            {isLoading ? (
                <SkeletonEarningCard />
            ) : (
                <React.Fragment>
                    <Card sx={{mb:2}}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h3">{t('Order Policies')}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h3" sx={{mb:2}}>{t('Privacy Policy')}</Typography>
                                    <Formik
                                        initialValues={{
                                            company_id: current?.company_id,
                                            user_id: current?._id,
                                            tag: process.env.REACT_APP_PRIVACY_POLICY,
                                            platform: process.env.REACT_APP_PLATFORM,
                                            type: privacyPolicy?.type?privacyPolicy?.type:'URL',
                                            policy_value: privacyPolicy?.policy_value,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            policy_value: Yup.string().required(t('Policy is required')),
                                        })}
                                        onSubmit={(values, { resetForm }) => handlePolicySubmit(values, resetForm)}
                                    >
                                        {({ setFieldValue, errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                            <form noValidate onSubmit={handleSubmit}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{fontWeight: 'bold'}}>{t('Which format do you prefer to upload your Privacy Policy?')}</Typography>
                                                        <RadioGroup
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            value={values.type}
                                                            onChange={(event) => {
                                                                setFieldValue('type', event.target.value);
                                                                setFieldValue('policy_value', '');
                                                            }}
                                                        >
                                                            <Box sx={{display: 'flex'}}>
                                                                <FormControlLabel value={"URL"} control={<Radio color="secondary" />} label={t('Upload a File')} />
                                                                <FormControlLabel value={"TEXT"} control={<Radio color="secondary" />} label={t('As Text')} />
                                                            </Box>
                                                        </RadioGroup>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {
                                                            values.type === 'URL' ?(
                                                                <React.Fragment>
                                                                    <Typography sx={{fontWeight: 'bold'}}>{t('Upload your Files')}</Typography>
                                                                    <DropZone key={1} onChange={(files) => {
                                                                        setFieldValue('policy_value', files[0])
                                                                    }} isPDF={true} limit={1}/>
                                                                    {touched.policy_value && errors.policy_value && (
                                                                        <FormHelperText error id="standard-weight-helper-text-policy_value-login">
                                                                            {errors.policy_value}
                                                                        </FormHelperText>
                                                                    )}
                                                                    {
                                                                        privacyPolicy?.policy_value && privacyPolicy?.type === 'URL' && (
                                                                            <img src={pdf_thumb} height="80"/>
                                                                        )
                                                                    }
                                                                </React.Fragment>
                                                            ):(
                                                                <React.Fragment>
                                                                    <Typography sx={{fontWeight: 'bold'}}>{t('Your Privacy Policy')}</Typography>
                                                                    <TextField
                                                                        type="text"
                                                                        variant="outlined"
                                                                        placeholder={t('Your Privacy Policy')}
                                                                        multiline
                                                                        rows={4}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        name="policy_value"
                                                                        value={
                                                                            values.policy_value?values.policy_value:
                                                                                privacyPolicy?.type === 'TEXT'?values?.policy_value:values.policy_value}
                                                                        error={Boolean(touched.policy_value && errors.policy_value)}
                                                                    />
                                                                    {touched.policy_value && errors.policy_value && (
                                                                        <FormHelperText error id="standard-weight-helper-text-policy_value-login">
                                                                            {errors.policy_value}
                                                                        </FormHelperText>
                                                                    )}
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LoadingButton type="submit"
                                                                       variant="contained"
                                                                       color="secondary"
                                                                       loading={ButtonLoading}
                                                                       size="small"
                                                                       loadingPosition="center">
                                                            {t('Submit')}
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h3" sx={{mb:2}}>{t('Terms & Conditions')}</Typography>
                                    <Formik
                                        initialValues={{
                                            company_id: current?.company_id,
                                            user_id: current?._id,
                                            tag: process.env.REACT_APP_TERMS_CONDITIONS,
                                            platform: process.env.REACT_APP_PLATFORM,
                                            type: termsConditions?.type?termsConditions?.type:'URL',
                                            policy_value: termsConditions?.policy_value,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            policy_value: Yup.string().required(t('Policy is required')),
                                        })}
                                        onSubmit={handlePolicySubmit}
                                    >
                                        {({ setFieldValue, errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                            <form noValidate onSubmit={handleSubmit}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{fontWeight: 'bold'}}>{t('Which format do you prefer to upload your Terms & Conditions?')}</Typography>
                                                        <RadioGroup
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            value={values.type}
                                                            onChange={(event) => {
                                                                setFieldValue('type', event.target.value);
                                                                setFieldValue('policy_value', '');
                                                            }}
                                                        >
                                                            <Box sx={{display: 'flex'}}>
                                                                <FormControlLabel value={"URL"} control={<Radio color="secondary" />} label={t('Upload a File')} />
                                                                <FormControlLabel value={"TEXT"} control={<Radio color="secondary" />} label={t('As Text')} />
                                                            </Box>
                                                        </RadioGroup>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {
                                                            values.type === 'URL' ?(
                                                                <React.Fragment>
                                                                    <Typography sx={{fontWeight: 'bold'}}>{t('Upload your Files')}</Typography>
                                                                    <DropZone key={1} onChange={(files) => {
                                                                        setFieldValue('policy_value', files[0])
                                                                    }} isPDF={true} limit={1}/>
                                                                    {touched.policy_value && errors.policy_value && (
                                                                        <FormHelperText error id="standard-weight-helper-text-policy_value-login">
                                                                            {errors.policy_value}
                                                                        </FormHelperText>
                                                                    )}
                                                                    {
                                                                        termsConditions?.policy_value && termsConditions?.type === 'URL' && (
                                                                            <img src={pdf_thumb} height="80"/>
                                                                        )
                                                                    }
                                                                </React.Fragment>
                                                            ):(
                                                                <React.Fragment>
                                                                    <Typography sx={{fontWeight: 'bold'}}>{t('Your Terms & Conditions')}</Typography>
                                                                    <TextField
                                                                        type="text"
                                                                        variant="outlined"
                                                                        placeholder={t('Your Privacy Policy')}
                                                                        multiline
                                                                        rows={4}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        name="policy_value"
                                                                        value={
                                                                            values.policy_value?values.policy_value:
                                                                                termsConditions?.type === 'TEXT'?values?.policy_value:values.policy_value}
                                                                        error={Boolean(touched.policy_value && errors.policy_value)}
                                                                    />
                                                                    {touched.policy_value && errors.policy_value && (
                                                                        <FormHelperText error id="standard-weight-helper-text-policy_value-login">
                                                                            {errors.policy_value}
                                                                        </FormHelperText>
                                                                    )}
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LoadingButton type="submit"
                                                                       variant="contained"
                                                                       color="secondary"
                                                                       loading={ButtonLoading2}
                                                                       size="small"
                                                                       loadingPosition="center">
                                                            {t('Submit')}
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </React.Fragment>

            )}
        </>
    );
};

export default Policy;
