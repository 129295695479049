import React from 'react';
import {Outlet, Navigate, useSearchParams, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {
    getPlanCart,
    getSubscribedServices,
    login,
    retrievePayment,
    subscribeService,
    updatePaymentRequest
} from "../api/api";
import {toast} from "react-toastify";

const isAuth = () => {
    const parse = JSON.parse(localStorage.getItem('auth_user'));
    const parse_type = JSON.parse(localStorage.getItem('auth_user_type'));
    return {
        type: parse_type?.type,
        register_status: parse?.register_status
    };
};

const ClientProtectedRoute = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    let charge_id = searchParams.get('tap_id')
    let user_id = searchParams.get('user_id')
    let adminCompanyId = searchParams.get('company_id');
    const inCheck = isAuth();
    if(!inCheck?.type && charge_id && user_id && adminCompanyId){
        const lang = localStorage.getItem('i18nextLng');
        localStorage.clear();
        localStorage.setItem('i18nextLng', lang);
        retrievePayment({charge_id: charge_id})
            .then((res) => {
                const response = res.data;
                if(response.code===1){
                    const responseCode = response.data.response.code;
                    const responseMsg = response.data.response.message;
                    const responseStatus = response.data.status;
                    const orderId = response.data.receipt.id;

                    if(responseCode==="516" || responseStatus!=='CAPTURED'){
                        toast.error(t('Payment failed'))
                    }
                    else{
                        const type = searchParams.get('type');
                        if(type && type === "REQUESTED-PAYMENT"){

                            let paymentRequestData;
                            const formData = {
                                company_id: adminCompanyId,
                                user_id: user_id,
                            };
                            getSubscribedServices(formData).then((res) => {
                                paymentRequestData = res.data.payment_request;
                                const formData = {
                                    payment_req_id: paymentRequestData?._id,
                                    total_price: paymentRequestData?.total_price,
                                    transaction_id: orderId,
                                    is_bank: false,
                                    company_id: paymentRequestData?.company_id,
                                    user_id: paymentRequestData?.user_id,
                                    platform: process.env.REACT_APP_PLATFORM,
                                };

                                updatePaymentRequest(formData).then((res) => {
                                    const response = res.data;
                                    if(response.code===1){
                                        toast.success(i18n.language==='ar'?response.ar_message:response.en_message);
                                        searchParams.delete('tap_id');
                                        searchParams.delete('type');
                                        setSearchParams(searchParams);

                                    }
                                    else{
                                        toast.error(i18n.language==='ar'?response.ar_message:response.en_message)
                                    }
                                })
                                    .catch((err) => {
                                        if (err?.response?.status === 200) {
                                            toast.error(i18n.language==='ar'?err.response?.data?.ar_message:err.response?.data?.en_message);
                                        }
                                    });
                            });

                        }
                        else {
                            let cartData;
                            const formData = {
                                company_id: adminCompanyId,
                                user_id: user_id,
                            };
                            getPlanCart(formData).then((resp) => {
                                cartData = resp.data.data;

                                const formData = {
                                    total_price: cartData?.total_price,
                                    cart_id: cartData?._id,
                                    transaction_id: orderId,
                                };
                                subscribeService(formData).then((res) => {
                                    const response = res.data;
                                    if (response.code === 1) {
                                        if (orderId) {
                                            searchParams.delete('tap_id');
                                            searchParams.delete('user_id');
                                            searchParams.delete('company_id');
                                            setSearchParams(searchParams);
                                        }
                                        localStorage.setItem('auth_user', JSON.stringify(response.data));
                                        localStorage.setItem(
                                            'token',
                                            JSON.stringify({
                                                token: response.token
                                            })
                                        );
                                        localStorage.setItem(
                                            'auth_user_type',
                                            JSON.stringify({
                                                type: response.data.user_type
                                            })
                                        );

                                        if (response.data.register_status === process.env.REACT_APP_REGISTER_OTP) {
                                            navigate('/register');
                                        } else if (response.data.register_status === process.env.REACT_APP_REGISTER_COMPANY) {
                                            navigate('/register');
                                        } else if (response.data.register_status === process.env.REACT_APP_REGISTER_PURCHASE) {
                                            navigate('/register');
                                        } else {
                                            navigate('/dashboard');
                                        }

                                        const inCheck = isAuth();

                                        return inCheck?.type === 'PROVIDER' && inCheck?.register_status === 'FINISH' ? <Outlet /> : <Navigate to="/login" />;
                                    } else {
                                        toast.error(i18n.language === 'ar' ? response.ar_message : response.en_message)
                                    }
                                })
                                // console.log(resp.data.data)
                            });
                        }
                    }
                }
            })
            .catch(err => {
                toast.error(i18n.language==='ar'?err.response?.data?.ar_message:err.response?.data?.en_message);
            });
    }
    else if(adminCompanyId){
        const lang = localStorage.getItem('i18nextLng');
        localStorage.clear();
        localStorage.setItem('i18nextLng', lang);
        let values = {
            company_id: adminCompanyId,
            device_type: process.env.REACT_APP_DEVICE_TYPE,//'W',
            firebase_token: localStorage.getItem('fcm'),
            device_id: localStorage.getItem('fcm'),
            latitude: localStorage.getItem('lat'),
            longitude: localStorage.getItem('lng'),
            social_id: null,
            login_type: process.env.REACT_APP_LOGIN_TYPE
        }

        login(values)
            .then((res) => {

                let response = res.data;
                if(response.code===1) {
                    // toast.success(i18n.language === 'ar' ? res.data.ar_message : res.data.en_message);

                    if(response.data){

                        localStorage.setItem('auth_user',JSON.stringify(res.data.data));
                        localStorage.setItem(
                            'token',
                            JSON.stringify({
                                token: res.data.token
                            })
                        );
                        localStorage.setItem(
                            'auth_user_type',
                            JSON.stringify({
                                type: res.data.data.user_type
                            })
                        );

                        if (response.data.register_status === process.env.REACT_APP_REGISTER_OTP) {
                            navigate('/register');
                        } else if (response.data.register_status === process.env.REACT_APP_REGISTER_COMPANY) {
                            navigate('/register');
                        } else if (response.data.register_status === process.env.REACT_APP_REGISTER_PURCHASE) {
                            if(searchParams.get('tap_id')){
                                navigate('/register?tap_id='+searchParams.get('tap_id'));
                            }
                            else{
                                navigate('/register');
                            }

                        } else {
                            navigate('/dashboard');
                        }
                    }
                }
                else{
                    navigate('/logout')
                    toast.error(i18n.language === 'ar' ? res.data.ar_message : res.data.en_message);
                }
            })
            .catch((err) => {
                if (err?.response?.status === 200) {
                    navigate('/logout')
                    toast.error(err.response?.data?.en_message);
                }
            });
    }
    else {
        return inCheck?.type === 'PROVIDER' && inCheck?.register_status === 'FINISH' ? <Outlet/> :
            <Navigate to="/login"/>;
    }
};

export { ClientProtectedRoute, isAuth };
