import React, {Component, useEffect, useRef, useState} from 'react';
import { GoogleMap, withScriptjs, InfoWindow, Marker ,useJsApiLoader} from "@react-google-maps/api";
import Geocode from "react-geocode";
import Autocomplete, {usePlacesWidget} from 'react-google-autocomplete';
import {TextField, Typography} from '@mui/material';
import {getCompanyById} from "../../../api/api";
import {toast} from "react-toastify";
const GOOGLE_MAPS_API_KEY = 'AIzaSyAxUB88Usr19MSM639HyjETY3llO2Rd_SI';

Geocode.setApiKey( GOOGLE_MAPS_API_KEY );
Geocode.enableDebug();
// const loaded = useRef(false);


const containerStyle = {
    width: '100%',
    height: '400px'
};

// const center = {
//     lat: -3.745,
//     lng: -38.523
// };

const NewMap = ({center, zoom, onChange, oldAddress}) => {
    const [libraries, setLibraries] = useState(['places']);
    // const [loaded, setLoaded] = useState();
    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    //     libraries: libraries,
    // })
    const [isLoaded, setLoaded] = useState(true);
    const [customCenter, setCenter] = useState(center);
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [area, setArea] = useState();
    const [state, setState] = useState();
    const [mapPosition, setMapPosition] = useState({
        lat: center.lat,
        lng: center.lng
    });
    const [markerPosition, setMarkerPostion] = useState({
        lat: center.lat,
        lng: center.lng
    });


    /**
     * Get the current address from the default map position and set those values in the state
     */

    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    const getCity = ( addressArray ) => {
        let city = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0] && 'locality' === addressArray[ i ].types[0] ) {
                city = addressArray[ i ].long_name;
                return city;
            }
        }
    };
    /**
     * Get the area and set the area input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    const getArea = ( addressArray ) => {
        let area = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0]  ) {
                for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                    if ( 'sublocality_level_1' === addressArray[ i ].types[j] || 'locality' === addressArray[ i ].types[j] ) {
                        area = addressArray[ i ].long_name;
                        return area;
                    }
                }
            }
        }
    };
    /**
     * Get the address and set the address input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    const getState = ( addressArray ) => {
        let state = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'administrative_area_level_1' === addressArray[ i ].types[0] ) {
                    state = addressArray[ i ].long_name;
                    return state;
                }
            }
        }
    };
    /**
     * And function for city,state and address input
     * @param event
     */
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    const onMarkerDragEnd = ( event ) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        setMapAddress(newLat, newLng)
    };


    const setMapAddress = (newLat, newLng) => {
        Geocode.fromLatLng( newLat , newLng ).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray =  response.results[0].address_components,
                    city = getCity( addressArray ),
                    area = getArea( addressArray ),
                    state = getState( addressArray );
                setAddress(address);
                onChange({
                    address: address,
                    city: getCity( addressArray ),
                    lat: newLat,
                    lng: newLng
                });
                setMapPosition({
                    lat: newLat,
                    lng: newLng
                });

                setCenter({
                    lat: newLat,
                    lng: newLng
                })

                setMarkerPostion({
                    lat: newLat,
                    lng: newLng
                })
            },
            error => {
                console.error(error);
            }
        );
    }

    /**
     * When the user types an address in the search box
     * @param place
     */
    const onPlaceSelected = ( place ) => {
        const address = place.formatted_address,
            addressArray =  place.address_components,
            city = getCity( addressArray ),
            area = getArea( addressArray ),
            state = getState( addressArray ),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();
        // Set these values in the state.
        setMapPosition({
            lat: latValue,
            lng: lngValue
        });

        setCenter({
            lat: latValue,
            lng: lngValue
        })

        setMarkerPostion({
            lat: latValue,
            lng: lngValue
        })
        onChange({
            address: address,
            city: getCity(place.address_components),
            lat: latValue,
            lng: lngValue
        })
    };

    const [map, setMap] = React.useState(null);
    const mapRef = useRef(null);


    const areCoordinatesDifferent = (coords1, coords2, tolerance = 0.0001) => {
        const latDiff = Math.abs(coords1.lat - coords2.lat);
        const lngDiff = Math.abs(coords1.lng - coords2.lng);
        return latDiff > tolerance || lngDiff > tolerance;
    };


    const onMapIdle = React.useCallback(() => {
        if (mapRef.current) {
            const center = mapRef.current.getCenter();
            const newCenter = {
                lat: parseFloat(center.lat().toFixed(6)),
                lng: parseFloat(center.lng().toFixed(6))
            };
            if (customCenter && areCoordinatesDifferent(customCenter, newCenter)) {
                setCenter(prevCenter => {
                    if (areCoordinatesDifferent(prevCenter, newCenter)) {
                        setMarkerPostion(newCenter);
                        // Call onChange or any other effect
                        setMapAddress(center.lat(), center.lng())
                        // onChange({ ...newCenter, address: oldAddress });
                        return newCenter;
                    }
                    return prevCenter;
                });
            }
        }
    }, [onChange, oldAddress,customCenter]);


    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        mapRef.current = map;
        map.setZoom(zoom)
        Geocode.fromLatLng( center.lat , center.lng ).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray =  response.results[0].address_components,
                    city = getCity( addressArray ),
                    area = getArea( addressArray ),
                    state = getState( addressArray );
                setAddress(address);
                onChange({
                    address: address,
                    city: getCity( addressArray ),
                    lat: center.lat,
                    lng: center.lng
                });
            },
            error => {
                console.error(error);
            }
        )
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    const { ref: materialRef, autocompleteRef } = usePlacesWidget({
        apiKey: GOOGLE_MAPS_API_KEY,//process.env.GOOGLE_MAPS,
        onPlaceSelected: onPlaceSelected,
        // inputAutocompleteValue: "country",
        // options: {
        //     componentRestrictions: { country },
        // },
    });

    const inputRef = useRef()
    const inputStyle= {
        borderColor: '#90caf925',
        width: '100%',
        background: '#f8fafc',
        animationDuration: '10ms',
        color: '#121926',
        fontWeight: '500',
        padding: '15.5px 14px',
        borderRadius: '12px',
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
    }



    useEffect(() => {
        const autoComplete = new window.google.maps.places.Autocomplete(
            inputRef.current,
        )

        autoComplete.addListener('place_changed', () => {
            const place = autoComplete.getPlace()
            if (!place.geometry || !place.geometry.location) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                alert("this location not available")
            }
            if (place.geometry.viewport || place.geometry.location) {
                // do something
                onPlaceSelected(place);
            }
        })
    }, []);


    return isLoaded?(
        (
            <React.Fragment>

                <input
                    defaultValue={address}
                    placeholder='type your location'
                    ref={inputRef}
                    style={inputStyle}
                />

                <GoogleMap
                    google={window.google.maps}
                    defaultZoom={zoom}
                    zoom={5}
                    defaultCenter={{lat: mapPosition.lat, lng: mapPosition.lng}}
                    mapContainerStyle={containerStyle}
                    center={customCenter}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onIdle={onMapIdle}
                >
                    {/* InfoWindow on top of marker */}

                    <Marker
                        name={'Dolores park'}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                        position={{lat: markerPosition.lat, lng: markerPosition.lng}}
                    />
                    {/*<df draggable={true}*/}
                    {/*    onDragEnd={onMarkerDragEnd}*/}
                    {/*    position={markerPosition} />*/}

                    {/* For Auto complete Search Box */}
                </GoogleMap>
                <Typography>{oldAddress}</Typography>
            </React.Fragment>
        )
        )
        : (
            <></>
        )


}

export default NewMap