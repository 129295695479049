// reducers.js
const initialState = {
    loading: true,
    totalCount: 0,
    specialityArr: [],
    data: [],
    // Add other initial state properties as needed
};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PROJECT_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_PROJECT':
            return { ...state, data: action.payload };
        case 'SET_PROJECT_COUNT':
            return { ...state, totalCount: action.payload };
        case 'SET_PROJECT_SPECIALITY':
            return { ...state, specialityArr: action.payload };
        // Add cases for other actions as needed
        default:
            return state;
    }
};

export default projectReducer;
