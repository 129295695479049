import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './reducers/customizationReducer';
import notificationReducer from "./reducers/notificationReducer";
import inquiryReducer from "./reducers/inquiryReducer";
import quoteReducer from "./reducers/quoteReducer";
import orderReducer from "./reducers/orderReducer";
import chatReducer from "./reducers/chatReducer";
import projectReducer from "./reducers/projectReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    notificationReducer: notificationReducer,
    inquiry: inquiryReducer,
    quotation: quoteReducer,
    order: orderReducer,
    chat: chatReducer,
    customerProject: projectReducer,
});

export default reducer;
