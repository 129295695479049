import {Grid, Typography} from '@mui/material';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Badge from '@mui/material/Badge';
import {useTranslation} from "react-i18next";
import file_thumb from 'assets/images/doc.png'
import pdf_thumb from 'assets/images/pdf.png'
import csv_thumb from 'assets/images/csv.png'
import xls_thumb from 'assets/images/xls.png'
const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
`;

const DropZone = ({ onChange, limit, isFile, isImport, isImg, isPDF }) => {
    const { t, i18n } = useTranslation();
    if(isFile){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
            maxFiles: limit ? limit : 2,
            onDrop: (acceptedFiles) => onChange(acceptedFiles),
            accept: {
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/vnd.ms-excel': ['.xls']
            }
        });


        const files = acceptedFiles.map((file,index) => {
            let thumbnail = xls_thumb;
            let textStr = "You've uploaded an excel file";
            if(file.type.includes('doc') || file.type.includes('doc')){
                thumbnail = file_thumb;
                textStr = "You've uploaded a docs";
            }
            else if(file.type.includes('pdf')){
                thumbnail = pdf_thumb;
                textStr = "You've uploaded a pdf";
            }
            else if(file.type.includes('csv')){
                thumbnail = csv_thumb;
                textStr = "You've uploaded a csv file";
            }
            return (
                <Grid key={index} item xs={12} sx={{textAlign: 'center'}}>
                    <img key={index} src={thumbnail} alt="" height={80}/>
                    <Typography sx={{color: 'red'}}>{t(textStr)}</Typography>
                </Grid>
            );
        });

        return (
            <div className="container">
                <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <p>{t('Drag \'n\' drop some files here, or click to select files')}</p>

                    <Grid container>{files}</Grid>
                </Container>
            </div>
        );
    }
    else if(isImport){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
            maxFiles: 1,
            onDrop: (acceptedFiles) => onChange(acceptedFiles),
            accept: {
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/vnd.ms-excel': ['.xls'],
                'text/csv': ['.csv']
            }
        });


        const files = acceptedFiles.map((file,index) => {
            let thumbnail = xls_thumb;
            let textStr = "You've uploaded an excel file";
            if(file.type.includes('csv')){
                thumbnail = csv_thumb;
                textStr = "You've uploaded a csv file";
            }
            return (
                <Grid key={index} item xs={12} sx={{textAlign: 'center'}}>
                    <img key={index} src={thumbnail} alt="" height={80}/>
                    <Typography sx={{color: 'red'}}>{t(textStr)}</Typography>
                </Grid>
            );
        });

        return (
            <div className="container">
                <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <p>{t('Drag \'n\' drop some files here, or click to select files')}</p>

                    <Grid container>{files}</Grid>
                </Container>
            </div>
        );
    }
    else if(isImg){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
            maxFiles: limit ? limit : 2,
            onDrop: (acceptedFiles) => onChange(acceptedFiles),
            accept: {
                'image/*': ['.png', '.jpeg', '.jpg']
            }
        });


        const files = acceptedFiles.map((file,index) => (
            <Grid key={index} item xs={12} sx={{textAlign: 'center'}}>
                <img key={index} src={URL.createObjectURL(file)} alt="" height={80}/>
                <Typography sx={{color: 'red'}}>{t("You've uploaded an image")}</Typography>
            </Grid>
        ));

        return (
            <div className="container">
                <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} accept="image/*" />
                    <p>{t('Drag \'n\' drop some files here, or click to select files')}</p>

                    <Grid container>{files}</Grid>
                </Container>
            </div>
        );
    }
    else if(isPDF){
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
            maxFiles: limit ? limit : 2,
            onDrop: (acceptedFiles) => onChange(acceptedFiles),
            accept: {
                'application/pdf': ['.pdf'],
            }
        });


        const files = acceptedFiles.map((file,index) => {
            let thumbnail = xls_thumb;
            let textStr = "You've uploaded an excel file";
            if(file.type.includes('doc') || file.type.includes('doc')){
                thumbnail = file_thumb;
                textStr = "You've uploaded a docs";
            }
            else if(file.type.includes('pdf')){
                thumbnail = pdf_thumb;
                textStr = "You've uploaded a pdf";
            }
            else if(file.type.includes('csv')){
                thumbnail = csv_thumb;
                textStr = "You've uploaded a csv file";
            }
            return (
                <Grid key={index} item xs={12} sx={{textAlign: 'center'}}>
                    <img key={index} src={thumbnail} alt="" height={80}/>
                    <Typography sx={{color: 'red'}}>{t(textStr)}</Typography>
                </Grid>
            );
        });

        return (
            <div className="container">
                <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <p>{t('Drag \'n\' drop some files here, or click to select files')}</p>

                    <Grid container>{files}</Grid>
                </Container>
            </div>
        );
    }
    else{
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
            maxFiles: limit ? limit : 2,
            onDrop: (acceptedFiles) => onChange(acceptedFiles)
        });


        const files = acceptedFiles.map((file,index) => {
            let thumbnail = file_thumb;
            let textStr = "You've uploaded a docs";
            if(file.type.includes('pdf')){
                thumbnail = pdf_thumb;
                textStr = "You've uploaded a pdf";
            }
            else if(file.type.includes('image')){
                thumbnail = URL.createObjectURL(file);
                textStr = "You've uploaded an image";
            }
            return (
                <Grid key={index} item xs={12} sx={{textAlign: 'center'}}>
                    <img key={index} src={thumbnail} alt="" height={80}/>
                    <Typography sx={{color: 'red'}}>{t(textStr)}</Typography>
                </Grid>
            )
        });

        return (
            <div className="container">
                <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <p>{t('Drag \'n\' drop some files here, or click to select files')}</p>

                    <Grid container>{files}</Grid>
                </Container>
            </div>
        );
    }
};

export default DropZone;
